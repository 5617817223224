import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export default new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        {
          name: 'Accueil',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        {
          name: 'Profil',
          path: '/user',
          component: () => import('@/views/dashboard/pages/UserProfile'),
        },
        {
          name: 'Connexion',
          path: '/login',
          component: () => import('@/views/dashboard/pages/Login'),
        },
        {
          name: 'Inscription',
          path: '/sign_in',
          component: () => import('@/views/dashboard/pages/SignIn'),
        },
        {
          name: 'Calendrier',
          path: '/calendar',
          component: () => import('@/views/dashboard/pages/Calendar'),
        },
        {
          name: 'Acces',
          path: '/access',
          component: () => import('@/views/dashboard/pages/Access'),
        },
        {
          name: 'Ajout',
          path: '/add_event',
          component: () => import('@/views/dashboard/pages/AddEvent'),
        },
        {
          name: 'Inscriptions',
          path: '/inscriptions',
          component: () => import('@/views/dashboard/pages/Inscriptions'),
        },
        {
          name: 'Profil',
          path: '/profile',
          component: () => import('@/views/dashboard/pages/Profile'),
        },
        {
          name: 'Planning',
          path: '/planning',
          component: () => import('@/views/dashboard/pages/Planning'),
        },
        {
          name: 'Distribution',
          path: '/distribution',
          component: () => import('@/views/dashboard/pages/Distribution'),
        },
        {
          name: 'Intervenants',
          path: '/intervenants',
          component: () => import('@/views/dashboard/pages/Intervenants'),
        },
        {
          name: 'Ateliers',
          path: '/ateliers',
          component: () => import('@/views/dashboard/pages/Ateliers'),
        },
        {
          name: 'Extraction',
          path: '/extraction',
          component: () => import('@/views/dashboard/pages/Extraction'),
        },
      ],
    },
  ],
})
