import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    userId: '',
    userName: null,
    userLastName: null,
    userEmail: null,
    isAdmin: '',
  },
  mutations: {
    LOG_OUT_USER (state) {
      state.userId = ''
      state.userName = null
      state.userLastName = null
      state.userEmail = null
      state.isAdmin = null
    },
    LOG_IN_USER (state, payload) {
      state.userId = payload.id
      state.userName = payload.name
      state.userLastName = payload.lastName
      state.userEmail = payload.email
      state.isAdmin = payload.isAdmin
    },
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
  },
  actions: {
    modifyUser: (store, id, name, lastName, email) => {
      store.commit('LOG_IN_USER', id, name, lastName, email)
    },
    logOutUser: (store) => {
      store.commit('LOG_OUT_USER')
    },
  },
  plugins: [
    createPersistedState({
      storage: window.sessionStorage,
    }),
  ],
})
